<template>
  <transition name="modal">
    <div class="modal-mask" @click="hide()" style="overflow-y: scroll; background-color: rgba(0, 0, 0, .4);">
      <div v-if="getModalInfo" class="modal-container" @click.stop>

        <vue-cropperjs
          v-show="imageLoaded"
          ref="cropper"
          :viewMode="this.viewMode"
          :aspectRatio="NaN"
          dragMode="move"
          :ready="runWhenCropperReady"
          :minContainerWidth="650"
          :minContainerHeight="400"
          :minCropBoxWidth="100"
          :minCropBoxHeight="100"
          :zoomOnWheel="false"
          :background="false"
          :toggleDragModeOnDblclick="false"
          :rotatable="false"
          :guides="false"
          :responsive="false"
          :cropBoxResizable="false"
          :cropBoxMovable="false"
        />
        <loader v-show="!imageLoaded" />


        <div  v-show="imageLoaded" id="slidecontainer">
          <img @click="zoomCrop(minSliderRange)" style="cursor: pointer; width: 16px; height: 16px; margin-right: 10px; opacity: 0.6;"
               src="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-image-128.png"/>
          <input type="range" :style="{'background': 'linear-gradient(90deg, #fd4056 '+ ( (sliderRange - minSliderRange) * (100/ (maxSliderRange - minSliderRange)) )  +'%, #FFF 1%)'}" :min="minSliderRange" :max="maxSliderRange" step="0.01" :value="sliderRange" class="slider">
          <img @click="zoomCrop(maxSliderRange)" style="cursor: pointer; width: 24px; height: 24px; margin-left: 10px; opacity: 0.6;"
               src="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-image-128.png"/>
        </div>


        <div style="margin-top: 20px; display: flex; justify-content: space-between; align-items: flex-end; width: 100%">
          <p style="float: left; font-weight: 500;cursor: pointer;" @click="hide()">İptal</p>
          <button v-show="imageLoaded" class="super-button" @click="sendImageToEventBus()">Kaydet</button>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
  import vueCropper from "vue-cropperjs";
  import Loader from "../../components/toolbox/loader.vue";

  export default {
    name: "src-pages-modals-cropper-v1",
    components: {
      Loader,
      'vue-cropperjs': vueCropper
    },
    data() {
      return {
        file: null,
        filename: null,
        sliderRange: 0,
        minSliderRange: 0,
        maxSliderRange: 5,
        imageLoaded: false,
        imageHeight: null,
        imageWidth: null,
        canvasHeight: null,
        canvasWidth: null,
        viewMode: 1,
        reviewShapeIsCube: false,
        fileType: null
      }
    },
    methods: {
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },
      sendImageToEventBus() {
        if (this.$refs.cropper) {
          let file = this.dataURLtoFile(
            this.$refs.cropper.getCroppedCanvas({"width": this.canvasWidth, "height": this.canvasHeight}).toDataURL(), this.filename
          )
          this.EventBus.$emit('cropperImageFile', {file: file, fileType: this.fileType})
          this.hide()
        }
      },
      setImage(file) {
        if (!file.type.includes('image/')) {
          alert('Please select an image file');
          return;
        }
        this.filename = file.name

        if (typeof FileReader === 'function') {
          const reader = new FileReader()
          reader.onload = (event) => {
            // rebuild cropperjs with the updated source
            this.$refs.cropper.replace(event.target.result);
          }
          reader.readAsDataURL(file);
        } else {
          alert('Sorry, FileReader API not supported')
        }
      },
      listenerForInput() {
        let rng = document.querySelector(".slider")
        let listener = () => {
          window.requestAnimationFrame(() => {
            this.sliderRange = rng.value
          })
        }

        rng.addEventListener("mousedown", function () {
          listener();
          rng.addEventListener("mousemove", listener);
        })
        rng.addEventListener("mouseup", function () {
          rng.removeEventListener("mousemove", listener);
        })
      },
      runWhenCropperReady() {
        let cropHeight = this.canvasHeight / this.divider;
        let cropWidth = this.canvasWidth / this.divider;

        let ratioX = (this.imageWidth < this.imageHeight ? cropWidth : cropHeight) / (this.imageWidth < this.imageHeight ? this.imageWidth : this.imageHeight);
        let ratioY = (this.imageWidth >= this.imageHeight ? cropWidth : cropHeight) / (this.imageWidth > this.imageHeight ? this.imageWidth : this.imageHeight);
        let finalRatio = ratioX > ratioY ? ratioX : ratioY;

        this.minSliderRange = finalRatio;
        this.sliderRange = finalRatio;

        if (this.$refs) {
          this.$refs.cropper.zoomTo(finalRatio);
          this.$refs.cropper.setCropBoxData({
            "top": (400 - cropHeight) / 2,
            "left": (650 - cropWidth) / 2,
            "width": cropWidth,
            "height": cropHeight
          });
        }

        this.imageLoaded = true;
      },
      zoomCrop(value) {
        this.sliderRange = value;
      }
    },
    computed: {
      getModalInfo() {
        if (!this.file) {
          this.file = this.$store.state.activeModal.info.file;
          this.canvasWidth = this.$store.state.activeModal.info.canvasPixel.width;
          this.canvasHeight = this.$store.state.activeModal.info.canvasPixel.height;
          this.divider = this.$store.state.activeModal.info.divider;
          this.viewMode = this.$store.state.activeModal.info.viewMode;
          this.reviewShapeIsCube = this.$store.state.activeModal.info.reviewShapeIsCube;
          this.fileType = this.$store.state.activeModal.info.fileType;
          let a = window.URL || window.webkitURL;

          let file, img;
          let that = this;
          if ((file = this.file)) {
            img = new Image();
            img.onload = function () {
              that.imageHeight = this.height;
              that.imageWidth = this.width;
              that.setImage(that.file);
            };
            img.src = a.createObjectURL(file);
          }
        }
        return true;
      }
    },
    watch: {
      sliderRange(newVal) {
        this.$refs.cropper.zoomTo(newVal);
      }
    },
    created() {
      Object.assign(this.$data, this.$options.data())
    },
    mounted() {
      this.listenerForInput()
    }
  }
</script>

<style scoped lang="scss">

  .modal-mask {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-container {
    width: 650px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 30px;
  }

  .super-button {
    height: 34px;
    min-width: 100px;
    float: right;
    background-color: #2d3640;
    &:hover {
      background-color: #242424;
    }
  }

  /*slider*/

  #slidecontainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  input[type=range] {
    /*removes default webkit styles*/
    -webkit-appearance: none;
    /*fix for FF unable to apply focus style bug */
    /*required for proper track sizing in FF*/
    width: 300px;
    height: 9px;
    padding: 0;
    border-radius: 5.5px;
    border: solid 1px #dfe4ec;
  }

  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 9px;
    border-radius: 5.5px;
    //background-color: #fa4359;
    border: 0;
  }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border: 4px solid #FFF;
    border-radius: 50%;
    background: #fd4056;
    margin-top: -6px;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 44, .5);
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    //background-color: #fa4359;
    cursor: pointer;
  }

  input[type=range]::-moz-range-track {
    width: 100%;
    height: 9px;
    border-radius: 5.5px;
    //background-color: #fa4359;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  input[type=range]::-moz-range-thumb {
    height: 12px;
    width: 12px;
    border: 4px solid #FFF;
    border-radius: 50%;
    background: #fd4056;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 44, .5);
  }

  /*hide the outline behind the border*/

  input[type=range]:-moz-focusring {
    outline: none;
  }

  input[type=range]::-moz-focus-outer {
    border: 0;
  }

  input[type=range]::-ms-track {
    width: 100%;
    height: 5px;
    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;
    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 6px 0;
    /*remove default tick marks*/
    color: transparent;
    cursor: pointer;
  }

  input[type=range]::-ms-fill-lower {
    background: #777;
    border-radius: 10px;
  }

  input[type=range]::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
  }

  input[type=range]::-ms-thumb {
    height: 20px;
    width: 20px;
    border: 4px solid #FFF;
    border-radius: 50%;
    background: #fd4056;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 44, .5);
  }

  input[type=range]:focus::-ms-fill-lower {
    background: #888;
  }

  input[type=range]:focus::-ms-fill-upper {
    background: #ccc;
  }

</style>
